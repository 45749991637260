































































































































































































































import Vue from "vue";
import { mapState } from "vuex";
import { Swiper } from "swiper";

export default Vue.extend({
  data() {
    return {
      activeIndex: 0,
      options: {
        direction: "vertical",
        speed: 600,
        mousewheel: true,
        shortSwipes: false,
        longSwipesMs: 50,
        longSwipesRatio: 0.05,
        autoplay: true,
        loop: true
      }
    };
  },
  computed: {
    ...mapState("assets", {
      oss: "oss",
      url: "url"
    }),
    ...mapState("app", {
      isCN: "isCN",
      isMobile: "isMobile",
      language: "language"
    }),
    swiper(): Swiper {
      return (this.$refs.swiper as any).$swiper;
    }
  },
  methods: {
    onSlideChange(swiper: Swiper) {
      this.activeIndex = swiper.realIndex;
    },
    slideTo(index: number) {
      this.swiper.slideToLoop(index);
    },
    scrollTo(title: HTMLElement) {
      window.scrollTo({ top: title.offsetTop - 60, behavior: "smooth" });
    }
  }
});
